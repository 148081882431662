@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin nav-bar {
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
  border-radius: 4px;
  position: relative;
  height: 50px;
  margin-bottom: 20px;
  border: 1px solid #e7e7e7;

  .nav-item {
    font-size: 14px;
    padding: 15px;
    color: #777;
    cursor: pointer;

    &.home {
      font-size: 18px;
    }

    :host-context(.desktop) &.logout {
      margin-left: auto;
      padding: 15px 30px;
    }

    &:hover {
      color: #555;
    }

    &.active {
      color: #555;
      background-color: #e7e7e7;
    }
  }
}

@mixin page-header {
  font-size: 24px;
  padding-bottom: 19px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eeeeee;
}

@mixin page-content {
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
}

@mixin button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  gap: 4px;
  padding: 6px 12px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;

  &:hover {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

@mixin button-primary {
  @include button;
  @include white-img;
  color: #fff;
  background-color: #337ab7;
  border: 1px solid #2e6da4;

  &:hover {
    color: #fff;
    background-color: darken(#337ab7, 10);
    border: 1px solid darken(#2e6da4, 10);
  }
}

@mixin button-add {
  @include button;
  @include white-img;
  color: #fff;
  background-color: #5cb85c;
  border: 1px solid #4cae4c;

  &:hover {
    color: #fff;
    background-color: #449d44;
    border: 1px solid #398439;
  }
}

@mixin button-warning {
  @include button;
  @include white-img;
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;

  &:hover {
    color: #fff;
    background-color: darken(#f0ad4e, 10);
    border: 1px solid darken(#eea236, 20);
  }
}

@mixin button-remove {
  @include button;
  @include white-img;
  color: #fff;
  background-color: #d9534f;
  border: 1px solid #d43f3a;
  outline: none;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #c9302c;
    border: 1px solid #ac2925;
  }
}

@mixin input {
  display: flex;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;

  &:disabled {
    cursor: default;
    background-color: #eeeeee;
    opacity: 1;
  }
}

@mixin white-img {
  img {
    filter: invert(100%) sepia(0%) saturate(200%) hue-rotate(13deg) brightness(118%) contrast(100%);
  }
}
