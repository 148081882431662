@import 'src/assets/mixins';

.mat-expansion-panel {
  @include nav-bar();
  box-shadow: none !important;
  height: auto;

  .mat-expansion-panel-header.mat-expanded {
    height: 50px;
    border-bottom: 1px solid #e7e7e7;
  }

  .mat-expansion-panel-header-title {
    margin: 0;
  }

  .mat-content.mat-content-hide-toggle {
    margin: 0;
  }

  .mat-expansion-panel-header {
    padding: 0;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.mat-mdc-cell span:empty:after {
  content: '--';
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
}

.mat-mdc-checkbox {
  width: 100%;

  .mdc-form-field {
    height: 21px;
    overflow: hidden;
    width: 100%;
  }

  label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.mdc-tooltip__surface {
  white-space: break-spaces;
}

.mat-table-empty {
  padding: 10px 24px;
}
