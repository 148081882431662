@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "src/assets/angular-material";
@import "src/assets/mixins";
@import "src/assets/variables";

@import 'ngx-toastr/toastr';

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

h4 {
  font-size: 18px;
  font-weight: 500;
}

